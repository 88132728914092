*,
::before,
::after {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
}

.container {
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: "100%";
  height: "100%";
  background: #f1f1f1;
  margin-top: "4%";
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
  font-weight: bold;
}

button {
  border: none;
}
/* .content-tabs {
  flex-grow: 1;
} */
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}
