.left-heading {
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-top: 4%;
}

.background {
  background-image: url("../assets/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.hoverable:hover {
  transform: scale(1.2);
}

.footer {
  text-align: center;
  color: #fff;
  margin-top: 10%;
}

.footer-1 {
  text-align: center;
  color: #fff;
  margin-top: 1%;
}
.footer-2 {
  text-align: center;
  color: #fff;
  margin-top: 1%;
}
