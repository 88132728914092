.bg-new{
background-color: #00b0f0;
color: #ffffff;
font-weight: bold;
}
.bg-nor{
    background-color:#113293;
}
:hover .bg-new{
    color: #ffffff;
}