.heading {
    font-size: 25px;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-top: 4%;
}

.neomorphism-button {
    display: inline-block;
    padding: 10px 26px;
    font-size: 20px;
    background-color: #f2f2f2;
    border-radius: 20px;
    box-shadow: -5px -5px 10px #ffffff, 5px 5px 10px #aaa7a7;
    color: #666666;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}

.neomorphism-button:hover {
    box-shadow: -2px -2px 5px #ffffff, 2px 2px 5px #d9d9d9;
    transform: translateY(2px);
}
