.site-collapse-custom-collapse .site-collapse-custom-panel-1 {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}
.site-collapse-custom-collapse .site-collapse-custom-panel-2 {
    margin-bottom: 24px;
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 2px;
}
.site-collapse-custom-collapse .site-collapse-custom-panel-3 {
    margin-bottom: 24px;
    overflow: hidden;
    background: #f7f7f7;
    border: 0px;
    border-radius: 2px;
}