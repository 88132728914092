@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,500;1,600;1,700;1,800&display=swap');


body {
    margin: 0;
    padding:0;
    /* font-family: "Open Sans", sans-serif; */
    font-family: 'Poppins', sans-serif;
}

html {
  font-family: 'Poppins', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;