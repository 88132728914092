.background-1 {
    background-image: url('../../../assets/Background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 20vh;
}

.footer-2 {
    text-align: center;
    color: #fff;
    margin-top: 2%;
}